export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterVFooterCopyright = () => import('../../components/Footer/VFooter-copyright.vue' /* webpackChunkName: "components/footer-v-footer-copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterVFooterForm = () => import('../../components/Footer/VFooter-form.vue' /* webpackChunkName: "components/footer-v-footer-form" */).then(c => wrapFunctional(c.default || c))
export const FooterVFooterMenu = () => import('../../components/Footer/VFooter-menu.vue' /* webpackChunkName: "components/footer-v-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const FooterVFooterNewsletter = () => import('../../components/Footer/VFooter-newsletter.vue' /* webpackChunkName: "components/footer-v-footer-newsletter" */).then(c => wrapFunctional(c.default || c))
export const FooterVFooterPayments = () => import('../../components/Footer/VFooter-payments.vue' /* webpackChunkName: "components/footer-v-footer-payments" */).then(c => wrapFunctional(c.default || c))
export const FooterVFooterServices = () => import('../../components/Footer/VFooter-services.vue' /* webpackChunkName: "components/footer-v-footer-services" */).then(c => wrapFunctional(c.default || c))
export const HeaderAction = () => import('../../components/Header/Action.vue' /* webpackChunkName: "components/header-action" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenu = () => import('../../components/Header/Menu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderPanelUser = () => import('../../components/Header/PanelUser.vue' /* webpackChunkName: "components/header-panel-user" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearch = () => import('../../components/Header/Search.vue' /* webpackChunkName: "components/header-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderVActionItensCartNew = () => import('../../components/Header/VAction-itens-cart-new.vue' /* webpackChunkName: "components/header-v-action-itens-cart-new" */).then(c => wrapFunctional(c.default || c))
export const HeaderVActionItensCart = () => import('../../components/Header/VAction-itens-cart.vue' /* webpackChunkName: "components/header-v-action-itens-cart" */).then(c => wrapFunctional(c.default || c))
export const HeaderVHeaderTopBar = () => import('../../components/Header/VHeader-top-bar.vue' /* webpackChunkName: "components/header-v-header-top-bar" */).then(c => wrapFunctional(c.default || c))
export const HeaderVMenuMobile = () => import('../../components/Header/VMenu-mobile.vue' /* webpackChunkName: "components/header-v-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderVMenuSub = () => import('../../components/Header/VMenu-sub.vue' /* webpackChunkName: "components/header-v-menu-sub" */).then(c => wrapFunctional(c.default || c))
export const HeaderVMenuSubBrand = () => import('../../components/Header/VMenu-subBrand.vue' /* webpackChunkName: "components/header-v-menu-sub-brand" */).then(c => wrapFunctional(c.default || c))
export const HeaderVMenuSubbrandsmobile = () => import('../../components/Header/VMenu-subbrandsmobile.vue' /* webpackChunkName: "components/header-v-menu-subbrandsmobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderVMenuSubmobile = () => import('../../components/Header/VMenu-submobile.vue' /* webpackChunkName: "components/header-v-menu-submobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderVPanelUserMobile = () => import('../../components/Header/VPanel-user-mobile.vue' /* webpackChunkName: "components/header-v-panel-user-mobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderVSearchSuggestion = () => import('../../components/Header/VSearch-suggestion.vue' /* webpackChunkName: "components/header-v-search-suggestion" */).then(c => wrapFunctional(c.default || c))
export const HeaderVSearchSuggestionProducts = () => import('../../components/Header/VSearch-suggestionProducts.vue' /* webpackChunkName: "components/header-v-search-suggestion-products" */).then(c => wrapFunctional(c.default || c))
export const StructureModal = () => import('../../components/Structure/Modal.vue' /* webpackChunkName: "components/structure-modal" */).then(c => wrapFunctional(c.default || c))
export const StructureOverlay = () => import('../../components/Structure/Overlay.vue' /* webpackChunkName: "components/structure-overlay" */).then(c => wrapFunctional(c.default || c))
export const StructureRules = () => import('../../components/Structure/Rules.vue' /* webpackChunkName: "components/structure-rules" */).then(c => wrapFunctional(c.default || c))
export const StructureWrapper = () => import('../../components/Structure/Wrapper.vue' /* webpackChunkName: "components/structure-wrapper" */).then(c => wrapFunctional(c.default || c))
export const StructureBannerFull = () => import('../../components/Structure/bannerFull.vue' /* webpackChunkName: "components/structure-banner-full" */).then(c => wrapFunctional(c.default || c))
export const StructureBreadcrumb = () => import('../../components/Structure/breadcrumb.vue' /* webpackChunkName: "components/structure-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const AccountEditCustomer = () => import('../../components/account/edit-customer.vue' /* webpackChunkName: "components/account-edit-customer" */).then(c => wrapFunctional(c.default || c))
export const AccountEditPassword = () => import('../../components/account/edit-password.vue' /* webpackChunkName: "components/account-edit-password" */).then(c => wrapFunctional(c.default || c))
export const BannersDoubleBanners = () => import('../../components/banners/Double-banners.vue' /* webpackChunkName: "components/banners-double-banners" */).then(c => wrapFunctional(c.default || c))
export const BannersFullBanners = () => import('../../components/banners/Full-banners.vue' /* webpackChunkName: "components/banners-full-banners" */).then(c => wrapFunctional(c.default || c))
export const BannersSimpleBanners = () => import('../../components/banners/Simple-banners.vue' /* webpackChunkName: "components/banners-simple-banners" */).then(c => wrapFunctional(c.default || c))
export const BannersThreeBanners = () => import('../../components/banners/Three-banners.vue' /* webpackChunkName: "components/banners-three-banners" */).then(c => wrapFunctional(c.default || c))
export const CarrosselBrands = () => import('../../components/carrossel/brands.vue' /* webpackChunkName: "components/carrossel-brands" */).then(c => wrapFunctional(c.default || c))
export const CarrosselProductsBannerFilters = () => import('../../components/carrossel/products-banner-filters.vue' /* webpackChunkName: "components/carrossel-products-banner-filters" */).then(c => wrapFunctional(c.default || c))
export const CarrosselProducts = () => import('../../components/carrossel/products.vue' /* webpackChunkName: "components/carrossel-products" */).then(c => wrapFunctional(c.default || c))
export const Category = () => import('../../components/category/category.vue' /* webpackChunkName: "components/category" */).then(c => wrapFunctional(c.default || c))
export const CategoryDesc = () => import('../../components/category/categoryDesc.vue' /* webpackChunkName: "components/category-desc" */).then(c => wrapFunctional(c.default || c))
export const CategoryNull = () => import('../../components/category/categoryNull.vue' /* webpackChunkName: "components/category-null" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilterItem = () => import('../../components/category/filterItem.vue' /* webpackChunkName: "components/category-filter-item" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilterMobile = () => import('../../components/category/filterMobile.vue' /* webpackChunkName: "components/category-filter-mobile" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilterSelected = () => import('../../components/category/filterSelected.vue' /* webpackChunkName: "components/category-filter-selected" */).then(c => wrapFunctional(c.default || c))
export const CategorySorting = () => import('../../components/category/sorting.vue' /* webpackChunkName: "components/category-sorting" */).then(c => wrapFunctional(c.default || c))
export const CategorySortingMobile = () => import('../../components/category/sortingMobile.vue' /* webpackChunkName: "components/category-sorting-mobile" */).then(c => wrapFunctional(c.default || c))
export const FacebookLoginFacebook = () => import('../../components/facebook/login-facebook.vue' /* webpackChunkName: "components/facebook-login-facebook" */).then(c => wrapFunctional(c.default || c))
export const FormatRelationshipCenter = () => import('../../components/format/RelationshipCenter.vue' /* webpackChunkName: "components/format-relationship-center" */).then(c => wrapFunctional(c.default || c))
export const FormatLanding = () => import('../../components/format/landing.vue' /* webpackChunkName: "components/format-landing" */).then(c => wrapFunctional(c.default || c))
export const FormatStatic = () => import('../../components/format/static.vue' /* webpackChunkName: "components/format-static" */).then(c => wrapFunctional(c.default || c))
export const FormatStatics = () => import('../../components/format/statics.vue' /* webpackChunkName: "components/format-statics" */).then(c => wrapFunctional(c.default || c))
export const GlobalsConverterLenses = () => import('../../components/globals/ConverterLenses.vue' /* webpackChunkName: "components/globals-converter-lenses" */).then(c => wrapFunctional(c.default || c))
export const GlobalsConverterLensesToric = () => import('../../components/globals/ConverterLensesToric.vue' /* webpackChunkName: "components/globals-converter-lenses-toric" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFieldOne = () => import('../../components/globals/FieldOne.vue' /* webpackChunkName: "components/globals-field-one" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFieldTwo = () => import('../../components/globals/FieldTwo.vue' /* webpackChunkName: "components/globals-field-two" */).then(c => wrapFunctional(c.default || c))
export const GlobalsFormWorkUs = () => import('../../components/globals/FormWorkUs.vue' /* webpackChunkName: "components/globals-form-work-us" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLoadingPublic = () => import('../../components/globals/Loading-public.vue' /* webpackChunkName: "components/globals-loading-public" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLoginNew = () => import('../../components/globals/Login-new.vue' /* webpackChunkName: "components/globals-login-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalsLogin = () => import('../../components/globals/Login.vue' /* webpackChunkName: "components/globals-login" */).then(c => wrapFunctional(c.default || c))
export const GlobalsMinicart = () => import('../../components/globals/Minicart.vue' /* webpackChunkName: "components/globals-minicart" */).then(c => wrapFunctional(c.default || c))
export const GlobalsModalGlobal = () => import('../../components/globals/ModalGlobal.vue' /* webpackChunkName: "components/globals-modal-global" */).then(c => wrapFunctional(c.default || c))
export const GlobalsModalcart = () => import('../../components/globals/Modalcart.vue' /* webpackChunkName: "components/globals-modalcart" */).then(c => wrapFunctional(c.default || c))
export const GlobalsModallogin = () => import('../../components/globals/Modallogin.vue' /* webpackChunkName: "components/globals-modallogin" */).then(c => wrapFunctional(c.default || c))
export const GlobalsNServices = () => import('../../components/globals/NServices.vue' /* webpackChunkName: "components/globals-n-services" */).then(c => wrapFunctional(c.default || c))
export const GlobalsNewuserNew = () => import('../../components/globals/Newuser-new.vue' /* webpackChunkName: "components/globals-newuser-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalsNewuser = () => import('../../components/globals/Newuser.vue' /* webpackChunkName: "components/globals-newuser" */).then(c => wrapFunctional(c.default || c))
export const GlobalsStar = () => import('../../components/globals/Star.vue' /* webpackChunkName: "components/globals-star" */).then(c => wrapFunctional(c.default || c))
export const GlobalsSubscriptionPopup = () => import('../../components/globals/Subscription-popup.vue' /* webpackChunkName: "components/globals-subscription-popup" */).then(c => wrapFunctional(c.default || c))
export const GlobalsThumbnail = () => import('../../components/globals/Thumbnail.vue' /* webpackChunkName: "components/globals-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const GoogleLoginGoogle = () => import('../../components/google/login-google.vue' /* webpackChunkName: "components/google-login-google" */).then(c => wrapFunctional(c.default || c))
export const LandingShortcutFilters = () => import('../../components/landing/Shortcut-filters.vue' /* webpackChunkName: "components/landing-shortcut-filters" */).then(c => wrapFunctional(c.default || c))
export const LandingSimple = () => import('../../components/landing/Simple.vue' /* webpackChunkName: "components/landing-simple" */).then(c => wrapFunctional(c.default || c))
export const LandingCountdown = () => import('../../components/landing/countdown.vue' /* webpackChunkName: "components/landing-countdown" */).then(c => wrapFunctional(c.default || c))
export const LandingForm = () => import('../../components/landing/form.vue' /* webpackChunkName: "components/landing-form" */).then(c => wrapFunctional(c.default || c))
export const LandingListbrands = () => import('../../components/landing/listbrands.vue' /* webpackChunkName: "components/landing-listbrands" */).then(c => wrapFunctional(c.default || c))
export const LandingListitens = () => import('../../components/landing/listitens.vue' /* webpackChunkName: "components/landing-listitens" */).then(c => wrapFunctional(c.default || c))
export const LandingLogo = () => import('../../components/landing/logo.vue' /* webpackChunkName: "components/landing-logo" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const MsgMessageNew = () => import('../../components/msg/message-new.vue' /* webpackChunkName: "components/msg-message-new" */).then(c => wrapFunctional(c.default || c))
export const MsgMessage = () => import('../../components/msg/message.vue' /* webpackChunkName: "components/msg-message" */).then(c => wrapFunctional(c.default || c))
export const NewFooterNFooterCopyright = () => import('../../components/new-Footer/NFooter-copyright.vue' /* webpackChunkName: "components/new-footer-n-footer-copyright" */).then(c => wrapFunctional(c.default || c))
export const NewFooterNFooterForm = () => import('../../components/new-Footer/NFooter-form.vue' /* webpackChunkName: "components/new-footer-n-footer-form" */).then(c => wrapFunctional(c.default || c))
export const NewFooterNFooterMenu = () => import('../../components/new-Footer/NFooter-menu.vue' /* webpackChunkName: "components/new-footer-n-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const NewFooterNFooterNewsletter = () => import('../../components/new-Footer/NFooter-newsletter.vue' /* webpackChunkName: "components/new-footer-n-footer-newsletter" */).then(c => wrapFunctional(c.default || c))
export const NewFooterNFooterPayments = () => import('../../components/new-Footer/NFooter-payments.vue' /* webpackChunkName: "components/new-footer-n-footer-payments" */).then(c => wrapFunctional(c.default || c))
export const NewFooterNFooterServices = () => import('../../components/new-Footer/NFooter-services.vue' /* webpackChunkName: "components/new-footer-n-footer-services" */).then(c => wrapFunctional(c.default || c))
export const NewFooterNFooter = () => import('../../components/new-Footer/NFooter.vue' /* webpackChunkName: "components/new-footer-n-footer" */).then(c => wrapFunctional(c.default || c))
export const NewProductNPowerField = () => import('../../components/new-product/NPowerField.vue' /* webpackChunkName: "components/new-product-n-power-field" */).then(c => wrapFunctional(c.default || c))
export const NewProductNPowerFieldOD = () => import('../../components/new-product/NPowerFieldOD.vue' /* webpackChunkName: "components/new-product-n-power-field-o-d" */).then(c => wrapFunctional(c.default || c))
export const NewProductNPowerFieldQTD = () => import('../../components/new-product/NPowerFieldQTD.vue' /* webpackChunkName: "components/new-product-n-power-field-q-t-d" */).then(c => wrapFunctional(c.default || c))
export const NewProductNProductActionForm = () => import('../../components/new-product/NProduct-actionForm.vue' /* webpackChunkName: "components/new-product-n-product-action-form" */).then(c => wrapFunctional(c.default || c))
export const NewProductNProductActionFormProduct = () => import('../../components/new-product/NProduct-actionFormProduct.vue' /* webpackChunkName: "components/new-product-n-product-action-form-product" */).then(c => wrapFunctional(c.default || c))
export const NewProductNProductBenefits = () => import('../../components/new-product/NProduct-benefits.vue' /* webpackChunkName: "components/new-product-n-product-benefits" */).then(c => wrapFunctional(c.default || c))
export const NewProductNProductDelivery = () => import('../../components/new-product/NProduct-delivery.vue' /* webpackChunkName: "components/new-product-n-product-delivery" */).then(c => wrapFunctional(c.default || c))
export const PageUserPLogin = () => import('../../components/page-user/PLogin.vue' /* webpackChunkName: "components/page-user-p-login" */).then(c => wrapFunctional(c.default || c))
export const PageUserLoginAndNew = () => import('../../components/page-user/UserLoginAndNew.vue' /* webpackChunkName: "components/page-user-login-and-new" */).then(c => wrapFunctional(c.default || c))
export const ProductActionPrices = () => import('../../components/product/ActionPrices.vue' /* webpackChunkName: "components/product-action-prices" */).then(c => wrapFunctional(c.default || c))
export const ProductVPowerField = () => import('../../components/product/VPowerField.vue' /* webpackChunkName: "components/product-v-power-field" */).then(c => wrapFunctional(c.default || c))
export const ProductVPowerFieldOD = () => import('../../components/product/VPowerFieldOD.vue' /* webpackChunkName: "components/product-v-power-field-o-d" */).then(c => wrapFunctional(c.default || c))
export const ProductVPowerFieldQty = () => import('../../components/product/VPowerFieldQty.vue' /* webpackChunkName: "components/product-v-power-field-qty" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductActionForm = () => import('../../components/product/VProduct-actionForm.vue' /* webpackChunkName: "components/product-v-product-action-form" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductActionFormProduct = () => import('../../components/product/VProduct-actionFormProduct.vue' /* webpackChunkName: "components/product-v-product-action-form-product" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductBenefits = () => import('../../components/product/VProduct-benefits.vue' /* webpackChunkName: "components/product-v-product-benefits" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductDelivery = () => import('../../components/product/VProduct-delivery.vue' /* webpackChunkName: "components/product-v-product-delivery" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductDescription = () => import('../../components/product/VProduct-description.vue' /* webpackChunkName: "components/product-v-product-description" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductEvaluations = () => import('../../components/product/VProduct-evaluations.vue' /* webpackChunkName: "components/product-v-product-evaluations" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductImage = () => import('../../components/product/VProduct-image.vue' /* webpackChunkName: "components/product-v-product-image" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductInfo = () => import('../../components/product/VProduct-info.vue' /* webpackChunkName: "components/product-v-product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductVProductVoucher = () => import('../../components/product/VProduct-voucher.vue' /* webpackChunkName: "components/product-v-product-voucher" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/product/product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const ProductCardFull = () => import('../../components/product-card/card-full.vue' /* webpackChunkName: "components/product-card-full" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product-card/card.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardCarousel = () => import('../../components/product-card/cardCarousel.vue' /* webpackChunkName: "components/product-card-carousel" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
